import React, { Component } from 'react';
import './ShareBtn.css';

/* function copyToClipboard(element) {
  var $temp = $("<input>");
  $("body").append($temp);
  $temp.val($(element).text()).select();
  document.execCommand("copy");
  $temp.remove();
} */


class ShareBtn extends Component {
  shareUrl = encodeURIComponent(document.location.origin + "/" + this.props.shareUrl);
  shareId = this.props.shareId;
  shareTitle = encodeURIComponent(this.props.shareTitle);
  shareStyle = this.props.shareStyle ? JSON.parse(this.props.shareStyle):undefined;
  shareMap = {
    'fb':'https://www.facebook.com/sharer.php?u=',
    'tw':'https://twitter.com/share?ref_src=',
    'in':'',
    'wa':'https://api.whatsapp.com/send?text=' + this.shareTitle + '%20'
  }
  share = (out) => {
    window.open(this.shareMap[out]+this.shareUrl, 
      "", 
      "width=500,height=300,location=no");
  }
  render() {
    return( 
      <div className="btn-share" style={this.shareStyle} onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
      }}>
        <span>Condividi</span>
        <div className="share-ico">
          <i title="Condividi su Facebook" onClick={(event)=>{
            this.share('fb');
            event.stopPropagation();
            event.preventDefault();
          }} className="fab fa-facebook-f"></i>
          <i title="Condividi su Twitter" onClick={(event)=>{
            this.share('tw');
            event.stopPropagation();
            event.preventDefault();
          }} className="fab fa-twitter"></i>
          <i title="Condividi su WhatsApp" onClick={(event)=>{
            this.share('wa');
            event.stopPropagation();
            event.preventDefault();
          }} className="fas fa-share-alt"></i>
          <i title="Stampa" onClick={(event)=>{
            window.pdfPreview(this.shareId);
            event.stopPropagation();
            event.preventDefault();
          }} className="fa fa-print"></i>
        </div>
      </div>
    );
  }
}
export default ShareBtn